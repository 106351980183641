import React, { memo, useEffect, useState } from 'react';
import TitlePartial from '@/components/ui/Title';
import { Link, Image, Icon, ArrowLeftIcon } from '@/components';
import { PagePath } from '@/layouts/shared';
import { TFunction } from 'next-i18next';
import { humanTimeArticle } from '@/libs/dayjs';
import { useFetchNewsQuery } from '../../api';
type ArticlesProps = {
  title: string;
  t: TFunction;
  id: number;
  slug: string;
};
const ArticlesPartial = ({ title, t, id, slug }: ArticlesProps) => {
  const [articles, setArticles] = useState<any[]>([]);
  const { refetch: refetchNews, isLoading: isLoadingNews } = useFetchNewsQuery(
    { page: 1, per_page: 4, categories: id },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setArticles(data.data),
    }
  );
  useEffect(() => {
    refetchNews();
  }, [refetchNews, articles]);
  const firstArticle = articles[0];
  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-text-lg md:tw-text-2xl tw-flex tw-items-center tw-flex-1  tw-relative">
          <div className="tw-flex tw-items-center tw-w-max tw-justify-center ">
            <Image src="/diamond.svg" alt="star" className="tw-h-5 tw-w-5" />
            <h2 className={`tw-flex-1 tw-block tw-px-4 tw-uppercase tw-text-primary-main tw-font-semibold`}>{title}</h2>
            <Image src="/diamond.svg" alt="star" className="tw-h-5 tw-w-5" />
          </div>
        </div>
        <div className="tw-text-center tw-text-md">
          <Link
            className="tw-italic tw-text-center tw-flex tw-items-center tw-w-max !tw-text-secondary-70"
            url={{
              pathname: PagePath.BlogPage,
              query: {
                id: slug,
              },
            }}
          >
            <span>Xem thêm</span>
            <Icon className="tw-w-4 tw-h-4 tw-rotate-180 tw-ml-2" icon={<ArrowLeftIcon />} />
          </Link>
        </div>
      </div>
      <div className="tw-w-full tw-justify-center tw-flex tw-gap-x-5 tw-mt-6">
        <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
          <div className="tw-w-full p-100p tw-bg-center tw-bg-cover tw-relative">
            <div className="tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-z-0">
              <Image
                className="tw-w-full tw-h-full"
                src={firstArticle?.thumbnail_url}
                alt={firstArticle?.title.rendered}
              />
            </div>
            <div className="tw-w-full tw-absolute tw-left-0 tw-bottom-0 tw-bg-black tw-bg-opacity-30 tw-px-4 tw-py-3 md:tw-px-6 md:tw-py-4 tw-text-white">
              <div className="tw-italic tw-text-sm md:tw-text-xl tw-font-light">
                {humanTimeArticle(new Date(firstArticle?.modified) + '', '')}
              </div>
              <Link
                url={{ pathname: PagePath.ArticlePage, query: { id: firstArticle?.slug } }}
                className="tw-mt-1 md:tw-mt-3 tw-text-md md:tw-text-2xl tw-font-bold  tw-line-clamp-1 !tw-text-white"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: firstArticle?.title.rendered,
                  }}
                ></div>
              </Link>
              <div
                className="tw-mt-3 md:tw-mt-5 tw-text-base md:tw-text-xl tw-font-normal tw-line-clamp-3 md:!tw-h-20 tw-overflow-hidden"
                dangerouslySetInnerHTML={{
                  __html: firstArticle?.excerpt.rendered,
                }}
                style={{ height: '70px' }}
              ></div>
            </div>
          </div>
          <div className="tw-w-full tw-flex tw-gap-y-4 tw-flex-col">
            {articles?.map((article, index) => {
              if ([1, 2, 3].includes(index))
                return (
                  <div className="tw-grid tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-4 lg:tw-gap-6" key={'atr-' + index}>
                    <Link url={{ pathname: PagePath.ArticlePage, query: { id: article.slug } }}>
                      <div className="tw-hidden md:tw-block tw-bg-cover tw-w-full tw-bg-center p-66p tw-relative">
                        <div className="tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-z-0">
                          <Image
                            className="tw-w-full tw-h-full"
                            src={article.thumbnail_url}
                            alt={article?.title.rendered}
                          />
                        </div>
                      </div>
                      <div className="tw-block md:tw-hidden tw-bg-cover tw-w-full tw-bg-center p-100p tw-relative">
                        <div className="tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-z-0">
                          <Image
                            className="tw-w-full tw-h-full"
                            src={article.thumbnail_url}
                            alt={article?.title.rendered}
                          />
                        </div>
                      </div>
                    </Link>
                    <div className="tw-col-span-2 md:tw-col-span-1">
                      <Link
                        className="tw-text-base md:tw-text-md"
                        url={{ pathname: PagePath.ArticlePage, query: { id: article.slug } }}
                      >
                        <div
                          className="tw-line-clamp-2 tw-font-bold"
                          dangerouslySetInnerHTML={{
                            __html: article?.title.rendered,
                          }}
                        ></div>
                      </Link>
                      <div className="tw-text-xs md:tw-text-base tw-italic tw-font-light tw-text-secondary-70 tw-mt-1">
                        {humanTimeArticle(new Date(article?.modified) + '', '')}
                      </div>
                      <div
                        className="tw-text-xs md:tw-text-base tw-text-secondary-70 tw-font-normal tw-mt-1 md:tw-mt-2 tw-line-clamp-2 tw-h-8 md:tw-h-12 tw-overflow-hidden"
                        dangerouslySetInnerHTML={{
                          __html: article?.excerpt.rendered,
                        }}
                      ></div>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ArticlesPartial);
