import React, { useEffect, useRef, useState } from 'react';
import { Link, Image, Icon, ArrowLeftIcon } from '@/components';
import { PagePath } from '@/layouts/shared';
import { TFunction } from 'next-i18next';
import toast from 'react-hot-toast';
import { ProductLoop } from '@/components/loop/ProductLoop';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';
import { Category, useFetchCategoryProducts } from '../../api';

type NewProductProps = {
  title: string;
  t: TFunction;
  catCode: string;
  isSale?: boolean;
};
const NewProduct = ({ catCode, t, title, isSale = false }: NewProductProps) => {
  const [category, setCategory] = useState<Category>();

  const { refetch } = useFetchCategoryProducts(catCode, {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: ({ message }) => toast.error(message),
    onSuccess: ({ data }) => setCategory(data),
  });
  useEffect(() => {
    refetch();
  }, [refetch]);

  const swiperRef = useRef<any>();
  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-text-lg md:tw-text-2xl tw-flex tw-items-center tw-flex-1  tw-relative">
          <div className="tw-flex tw-items-center tw-w-max tw-justify-center ">
            <Image src={isSale ? '/sale.gif' : '/diamond.svg'} alt="star" className="tw-h-5 tw-w-5" />
            <h2 className={`tw-flex-1 tw-block tw-px-4 tw-uppercase tw-text-primary-main tw-font-semibold`}>{title}</h2>
            <Image src={isSale ? '/sale.gif' : '/diamond.svg'} alt="star" className="tw-h-5 tw-w-5" />
          </div>
        </div>
        <div className="tw-text-center tw-text-md">
          <Link
            className="tw-italic tw-text-center tw-flex tw-items-center tw-w-max !tw-text-secondary-70"
            url={{ pathname: PagePath.CategoryPage, query: { id: catCode } }}
          >
            <span>Xem thêm</span>
            <Icon className="tw-w-4 tw-h-4 tw-rotate-180 tw-ml-2" icon={<ArrowLeftIcon />} />
          </Link>
        </div>
      </div>
      <div className="tw-mt-8 tw-w-full tw-h-full tw-leading-0 tw-relative tw-pb-[40%]">
        <Link
          className="tw-italic tw-text-center tw-flex tw-items-center tw-w-max !tw-text-secondary-70"
          url={{ pathname: PagePath.CategoryPage, query: { id: catCode } }}
        >
          <Image
            className="tw-w-full tw-h-full !tw-absolute tw-left-0 tw-top-0"
            src={category?.banner_images?.url}
            alt={category?.product_category_name}
          />
        </Link>
      </div>
      {category?.products && category?.products.length > 0 && (
        <div className="tw-block tw-relative tw-mt-8 lg:tw-mt-10">
          <button
            className="tw-hidden custom-prev tw-w-10 tw-h-10 tw-rounded-full tw-absolute tw-top-1/4 tw--left-10 lg:tw-flex tw-items-center tw-justify-center tw-z-10 tw-text-primary-main"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <Icon className="tw-w-full tw-h-full" icon={<ArrowLeftIcon />} />
          </button>
          <Swiper
            slidesPerView={4}
            spaceBetween={16}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2.5,
                spaceBetween: 8,
              },
              768: {
                slidesPerView: 3.5,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 16,
              },
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[EffectFade, Navigation]}
            className="product-loop-swiper tw-overflow-auto category-scrollbar"
            id="swiper"
          >
            {category &&
              category.products.map((item, key) => (
                <SwiperSlide key={`item-${key}`} className="tw-relative">
                  <ProductLoop catCode={item.cat_code} p={item} t={t}></ProductLoop>
                </SwiperSlide>
              ))}
          </Swiper>
          <button
            className="custom-next tw-hidden tw-w-10 tw-h-10 tw-absolute tw--right-10 tw-top-1/4 tw-text-primary-main lg:tw-flex tw-items-center tw-justify-center tw-rotate-180 tw-z-10"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Icon className="tw-w-full tw-h-full" icon={<ArrowLeftIcon />} />
          </button>
        </div>
      )}
    </div>
  );
};

export default NewProduct;
