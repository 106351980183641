import React, { memo, useState, useEffect } from 'react';

import { Link, Image, ArrowLeftIcon, Icon, Avatar } from '@/components';
import { LiveVideo } from '@/features/livestream/shared';
import { LiveStreamStatus, PagePath } from '@/layouts/shared';
import { TFunction } from 'next-i18next';
import toast from 'react-hot-toast';
import { useFetchLiveVideosHomeQuery } from '@/features/livestream/detail/api';
import { VideoLoop } from '@/components/loop/VideoLoop';
import { Product } from '@/features/products/shared';
import { Category, useFetchCategoryProducts } from '../../api';
import { ProductLoop } from '@/components/loop/ProductLoop';
import { humanDate } from '@/libs/dayjs';
import { createHandle, getLiveVideoImage } from '@/libs/app';
type NewLiveProps = {
  t: TFunction;
  title: string;
};
const NewLive = ({ t, title }: NewLiveProps) => {
  const [videos, setVideos] = useState<LiveVideo[]>([]);
  const [category, setCategory] = useState<Category>();
  const { refetch: refetchVideos } = useFetchLiveVideosHomeQuery(
    {},
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: ({ message }) => {
        toast.error(message);
      },
      onSuccess: ({ data }) => {
        setVideos(data);
      },
    }
  );
  const { refetch: refetchProducts } = useFetchCategoryProducts('MOI', {
    onSuccess: ({ data }) => setCategory(data),
  });
  useEffect(() => {
    refetchVideos();
  }, [refetchVideos]);
  useEffect(() => {
    refetchProducts();
  }, [refetchProducts]);
  return (
    <>
      <section
        className="new-videos tw-py-8 lg:tw-py-12 tw-bg-center tw-bg-cover tw-bg-no-repeat tw-mt-10"
        style={{
          backgroundImage: 'url("/bg-live.svg")',
        }}
      >
        <div className="container tw-mx-auto ">
          <div className="tw-w-full">
            <div className="tw-flex tw-justify-between tw-items-center">
              <div className="tw-text-lg md:tw-text-2xl tw-flex tw-items-center tw-flex-1  tw-relative">
                <div className="tw-flex tw-items-center tw-w-max tw-justify-center ">
                  <Image src="/diamond.svg" alt="star" className="tw-h-5 tw-w-5" />
                  <h2 className={`tw-flex-1 tw-block tw-px-4 tw-uppercase tw-text-primary-main tw-font-semibold`}>
                    {title}
                  </h2>
                  <Image src="/diamond.svg" alt="star" className="tw-h-5 tw-w-5" />
                </div>
              </div>
              <div className="tw-text-center tw-text-md">
                <Link
                  className="tw-italic tw-text-center tw-flex tw-items-center tw-w-max !tw-text-secondary-70"
                  url={{ pathname: PagePath.LiveVideosPage }}
                >
                  <span>Xem thêm</span>
                  <Icon className="tw-w-4 tw-h-4 tw-rotate-180 tw-ml-2" icon={<ArrowLeftIcon />} />
                </Link>
              </div>
            </div>

            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mt-8">
              <Link
                url={{
                  pathname: PagePath.LiveVideoPage,
                  query: { id: `${createHandle(videos[0]?.name)}-${videos[0]?.id}` },
                }}
                className="tw-bg-white tw-border tw-rounded-sm tw-border-gray-70 tw-flex tw-flex-col tw-overflow-hidden"
              >
                <div className="tw-relative tw-flex-1 tw-min-h-[200px]">
                  <Image
                    className="tw-w-full tw-h-full"
                    objectFit="cover"
                    src={getLiveVideoImage(videos[0]?.images)}
                    alt={videos[0]?.name}
                  />
                </div>
                <div className="md:tw-px-6 md:tw-py-4 tw-px-4 tw-py-3">
                  <div className="tw-flex tw-items-center">
                    {videos[0]?.status === LiveStreamStatus.LiveNow && (
                      <div className="tw-py-1 tw-px-4 tw-flex tw-items-center tw-bg-[#CA3A31] tw-text-white tw-rounded-md">
                        <span className="tw-w-2 tw-h-2 tw-rounded-full tw-bg-white tw-mr-2" />
                        <span>Live</span>
                      </div>
                    )}

                    <span className="tw-ml-4 tw-font-bold">{videos[0]?.name}</span>
                  </div>
                  <div className="tw-mt-3 tw-flex tw-items-center">
                    <div className="tw-flex tw-gap-x-2 tw-items-center tw-text-secondary-70 tw-font-medium">
                      <Avatar
                        src={videos[0]?.live_streamer.avatar}
                        className="tw-w-6 tw-h-6"
                        rounded="tw-rounded-full"
                      />
                      <h3 dangerouslySetInnerHTML={{ __html: videos[0]?.live_streamer.name }}></h3>
                    </div>
                    <div className="tw-text-secondary-10 tw-ml-5">
                      <span>{humanDate(videos[0]?.start_datetime)}</span>|<span>{videos[0]?.duration}</span>
                    </div>
                  </div>
                </div>
              </Link>

              <div className="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-gap-4">
                {category &&
                  category.products.length > 0 &&
                  category.products
                    .slice(0, 4)
                    .map((p) => <ProductLoop p={p} t={t} key={`product-${p.product_code}`} />)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default memo(NewLive);
